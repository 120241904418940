import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import SeatPicker from "react-seat-picker";

export default function SeatStructure(props) {
  // const [trackerOnline, setTrackerOnline] = useState(false);
  const [bookedBlockedSeats, setBookedBlockedSeats] = useState(
    props.selectedBus.bookedSeats.concat(props.selectedBus.blockedSeats)
  );
  const busStructure = props.selectedBus.busStructureName;
  const bb = busStructure.split(",");
  // const token = localStorage.getItem("liyu_login_token");

  // useEffect(() => {
  //   const server = "http://10.1.8.20:93";
  //   var socket = socketClient(server, { withCredentials: true });
  //   socket.on("connect", function () {
  //     console.log("Connected");
  //     socket.emit("seats_event", { tripId: props.selectedBus.id, token });
  //   });
  //   // setInterval(() => {
  //   //   socket.emit("seats_event", { tripId: props.selectedBus.id, token });
  //   // }, 5000);
  //   socket.on("receive_seats", function (data) {
  //     console.log(data);
  //     setBookedBlockedSeats(data.returnContent.seats);
  //   });
  //   socket.on("exception", function (data) {
  //     console.log("event", data);
  //   });
  //   socket.on("disconnect", function () {
  //     console.log("Disconnected");
  //   });
  //   return () => {
  //     socket.off("seats_event");
  //   };
  // }, []);

  let num = 0;
  // const bookedBlockedSeats = props.selectedBus.bookedSeats.concat(
  //   props.selectedBus.blockedSeats
  // );

  const selectedSeats = props.selectedSeats;
  const rows = bb.map((b, i) => {
    const roww = b.split("").map((da, j) => {
      if (da === "p") {
        num++;
        return {
          id: num,
          number: num,
          isReserved: bookedBlockedSeats.includes(num),
          isSelected: selectedSeats.includes(num),
        };
      } else {
        return null;
      }
    });
    return roww;
  });

  return (
    <SeatPicker
      addSeatCallback={({ row, number, id }, addCb) => {
        props.addSeat(id);
        addCb(row, number, id);
      }}
      removeSeatCallback={({ row, number, id }, removeCb) => {
        props.removeSeat(id);
        removeCb(row, number, id);
      }}
      rows={rows}
      maxReservableSeats={6}
      alpha
      visible
      selectedByDefault
      loading={false}
      tooltipProps={{ multiline: true }}
    />
  );
}
