import { BankName } from "./constants";

export const ApiUrl = {
  [BankName.ABAY]: "http://10.1.8.20:93",
  [BankName.ABYSSINIA]: "https://10.106.0.2",
  [BankName.BUNNA]:"http://10.106.0.2",
  [BankName.AWASH]:"https://api-v2.liyubus.com"
};

export const bankUrlHandler = (bank) => {
  let logedBank = localStorage.getItem("bank");
  if (localStorage.getItem("bank") === BankName.ABAY) {
    return "http://10.1.8.20:93";
  // return "https://api-v2.liyubus.com"
  } else if (localStorage.getItem("bank") === BankName.ABYSSINIA) {
    return "https://10.106.0.2";
  }
  else if(logedBank === BankName.BUNNA){
    return ApiUrl[BankName.BUNNA]
  }
  else if(logedBank === BankName.AWASH){
    console.log("awash awash");
    return ApiUrl[BankName.AWASH]
  }
  else {
    return "https://api-v2.liyubus.com";
  }
};
