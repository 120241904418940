import React from "react";
import NavBar from "../components/navBar";
import Stepperr from "../components/stepper";
import TabBar from "../components/tab";
import SearchReferenceNumber from "../components/search_ref";
import BookedTicket from "../components/bookedTicketList";
import Footer from "../components/footer";
import ActiveBookingTable from "../components/activeBookingTable";

export default function HomePage() {
  return (
    <div>
      <NavBar>
        <TabBar
          tab1={<Stepperr />}
          tab2={<ActiveBookingTable />}
          tab3={<BookedTicket />}
          tab4={<SearchReferenceNumber />}
        />
      </NavBar>
      <Footer />
    </div>
  );
}
