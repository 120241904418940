import React from "react";
import { Route, Redirect } from "react-router-dom";
import * as jwt from "jsonwebtoken";
import { setting } from "../config/setting";
import * as fns from "date-fns";
export const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const loginToken = localStorage.getItem("liyu_login_token");
        if (loginToken) {
          try {
            const user = jwt.verify(loginToken, setting.tokenKey);
            if (user) {
              return <Component {...props} />;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: "/signin",
                    state: props.location,
                  }}
                />
              );
            }
          } catch (err) {
            // console.log(err);
            return (
              <Redirect
                to={{
                  pathname: "/signin",
                  state: props.location,
                }}
              />
            );
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: "/signin",
                state: props.location,
              }}
            />
          );
        }
      }}
    />
  );
};

export const LoginRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        try {
          const loginToken = localStorage.getItem("liyu_login_token");
          if (loginToken) {
            const user = jwt.verify(loginToken, setting.tokenKey);
            if (user) {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: props.location,
                  }}
                />
              );
            } else {
              return <Component {...props} />;
            }
          } else {
            return <Component {...props} />;
          }
        } catch (err) {
          //console.log(err.name);
          return <Component {...props} />;
        }
      }}
    />
  );
};
