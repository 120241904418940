import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { bankUrlHandler } from "../config/api_url";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://liyubus.com/">
        Liyubus
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(8),
    // paddingBottom: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(6, 0, 2),
    backgroundColor: "#123456",
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  notchedOutline: {},
  focused: {
    "& $notchedOutline": {
      borderColor: "yellow",
    },
  },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#123456",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#123456",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#123456",
      },
    },
  },
})(TextField);

export default function SignInPage() {
  const classes = useStyles();
  const history = useHistory();
  const [val, setVal] = useState({ value: false, message: "" });
  const [bank, setBank] = useState("");
  const [password, setPassword] = useState("");
  const [loginRes, setLoginRes] = useState({
    loading: false,
    res: null,
    error: null,
  });

  const error = loginRes.error
    ? loginRes.error
    : loginRes.res
    ? loginRes.res.success
      ? false
      : loginRes.res.message
    : false;

  const handleLogin = () => {
    if (bank !== "") {
      if (password === "") {
        setVal({ value: true, message: "Password cannot be empty" });
      } else if (password.length < <i class="fal fa-signal-alt-3    "></i>) {
        setVal({
          error: true,
          message: "Password cannot be less than 3 characters",
        });
      } else {
        setVal({ value: false, message: "" });
        setLoginRes({ ...loginRes, loading: true });
        axios
          .post(
            `${bankUrlHandler()}/bank-user-auth/login`,
            {
              bankUser: bank,
              password: password,
            },
            {
              headers: {
                "x-api-key": "lE1Vud23Gi",
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) =>
            setLoginRes({ error: null, loading: false, res: res.data })
          )
          .catch((err) =>
            setLoginRes({ loading: false, error: err.message, res: null })
          );
      }
    }
  };

  if (loginRes.res) {
    if (loginRes.res.success) {
      localStorage.setItem("bank", bank);
      localStorage.setItem("liyu_login_token", loginRes.res.data.access_token);
      history.push("/");
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar
          alt="Liyu bus"
          src="/assets/liyulogo.png"
          className={classes.large}
        />
        <Typography component="h1" variant="h5" style={{ color: "#123456" }}>
          Liyubus Bank Portal
        </Typography>
        <div className={classes.form}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "100%" }}
          >
            <InputLabel id="demo-simple-select-outlined-label">Bank</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={bank}
              onChange={(event) => setBank(event.target.value)}
              label="Bank"
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="abay">Abay Bank</MenuItem>
              <MenuItem value="abyssinia">Abyssinia Bank</MenuItem>
              <MenuItem value="bunna">Bunna Bank</MenuItem>
              <MenuItem value="awash">Awash Bank</MenuItem>


            </Select>
          </FormControl>
          <CssTextField
            error={val.value}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            helperText={val.message}
          />
          {error ? <Alert severity="error">{error}</Alert> : null}

          {loginRes.loading ? (
            <CircularProgress />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => handleLogin()}
            >
              Sign In
            </Button>
          )}
        </div>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
