import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import React from "react";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import * as jwt from "jsonwebtoken";
import { setting } from "../config/setting";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  // grow: {
  //   flexGrow: 1,
  // },
  appBar: {
    position: "relative",
    backgroundColor: "#123456",
  },
  layout: {
    // width: "auto",
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // [theme.breakpoints.up(1200)]: {
    width: 1200,
    marginLeft: "auto",
    marginRight: "auto",
    // },
  },
  // paper: {
  //   marginTop: theme.spacing(3),
  //   marginBottom: theme.spacing(5),
  //   padding: theme.spacing(2),
  //   [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
  //     marginTop: theme.spacing(6),
  //     marginBottom: theme.spacing(6),
  //     padding: theme.spacing(3),
  //   },
  // },
  // stepper: {
  //   padding: theme.spacing(3, 0, 5),
  // },
  // buttons: {
  //   display: "flex",
  //   justifyContent: "flex-end",
  // },
  // button: {
  //   marginTop: theme.spacing(3),
  //   marginLeft: theme.spacing(1),
  // },
}));

export default function NavBar(props) {
  const [bankName, setBankName] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const loginToken = localStorage.getItem("liyu_login_token");
    const user = jwt.verify(loginToken, setting.tokenKey).user;
    setBankName(setting[user.bankName]);
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("liyu_login_token");
    history.push("/signin");
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              style={{ flexGrow: 1 }}
              noWrap
            >
              LIYUBUS - Bank Portal [{bankName}]
            </Typography>
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <main className={classes.layout}>{props.children}</main>
    </React.Fragment>
  );
}
