import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "./pages/homePage";
import { LoginRoute, ProtectedRoute } from "./pages/protected-route";
import SignInPage from "./pages/signinPage";
import Test from "./test";

function App() {
  return (
    <Router basename="/portal">
      <Switch>
        {/* {routes.map((route, i) => ( */}
        <ProtectedRoute exact path="/" component={HomePage} />
        <LoginRoute path="/signin" component={SignInPage} />
        <Route path="/test" component={Test} />
        {/* ))} */}
      </Switch>
    </Router>
  );  
}

export default App;
